<router-outlet></router-outlet>

<mat-toolbar color="primary">
  <img src="assets/m-white.svg" class="m-logo">
  <h1>
    iTunes to Excel
  </h1>
  <button mat-button routerLink="/">Home</button>
  <button mat-button routerLink="/about">About</button>
</mat-toolbar>
<div class="content">
  <router-outlet></router-outlet>
</div>