<p *ngIf="files[0] == undefined">Upload iTunes Connect sales report file, as descriped <a href="https://www.notion.so/melino/iTunes-Sales-Reports-7cd8e7144bd042d69f4724be061031d7" target="_blank">here</a>.</p>
<span *ngIf="files[0] != undefined && files[0].progress == 100 && !showDownload">
    Converting, please wait...<br><br>
    <mat-spinner></mat-spinner>
</span>
<mat-card *ngIf="files[0] != undefined && files[0].progress && files[0].progress < 100">
    Uploading...<br><br>
    <mat-progress-bar [value]="files[0].progress"></mat-progress-bar>
</mat-card>
<div *ngIf="showDownload">
    {{result.msg}}<br><br>
    <button mat-raised-button color="primary" (click)="download()">Download</button><br><br>
</div>
<button mat-raised-button color="primary" (click)="onClick()" *ngIf="files[0] == undefined">
    Upload
</button>
<input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept=".zip" style="display:none;" />
<!-- <button mat-raised-button color="primary" (click)="reload()" *ngIf="files[0] != undefined && files[0].progress == 100">
    <mat-icon>file_upload</mat-icon>
    Donwload
</button>
<button mat-raised-button color="primary" (click)="reload()" *ngIf="files[0] != undefined && files[0].progress == 100">
    <mat-icon>file_upload</mat-icon>
    New
</button> -->
